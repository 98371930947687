import { NAVIGATE_TO, CLEAR_NAVIGATE } from "../helpers/types";

// @desc  Navigate to specific route
export const navigateTo = (route) => (dispatch) => {
  dispatch({ type: NAVIGATE_TO, payload: route });
};

// @desc  Clear route
export const clearNavigate = () => {
  return { type: CLEAR_NAVIGATE };
};
