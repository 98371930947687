// Route
export const ROUTE_HOME = "/";
export const ROUTE_BLOG = "/blog";
export const ROUTE_LOGIN = "/login";
export const ROUTE_PROMO = "/promo";
export const ROUTE_USER = "/profile";
export const ROUTE_REWARD = "/reward";
export const ROUTE_VERIFY = "/verify";
export const ROUTE_TESTIMONIALS = "/review";
export const ROUTE_SIGNUP = "/register";
export const ROUTE_PRICING = "/pricing";
export const ROUTE_CHECKOUT = "/checkout";
export const ROUTE_PRACTICE = "/practice";
export const ROUTE_EXERCISE = "/exercise";
export const ROUTE_EXERCISE_MOCK = "/mock-test/exercise";
export const ROUTE_EXERCISE_MOCK_DESKTOP = "/mock-test/desktop";
export const ROUTE_NOT_FOUND = "/not-found";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_MOCK_TEST = "/mock-test";
export const ROUTE_TEMPLATES = "/templates";
export const ROUTE_RESOURCES = "/resources";
export const ROUTE_STATISTICS = "/statistics";
export const ROUTE_CONTACT_US = "/contact-us";
export const ROUTE_REGULATIONS = "/regulations";
export const ROUTE_PASSWORD = "/reset-password";
export const ROUTE_DESKTOP_ONLY = "/desktop-only";
export const ROUTE_SET_PASSWORD = "/set-password";
export const ROUTE_VOCABULARY = "/resources/vocabulary";
export const ROUTE_MOCK_TEST_RESULT = "/mock-test/results";
export const ROUTE_MOCK_TEST_SCORECARD = "/mock-test/scorecard";
export const ROUTE_MOBILE_APP = "/download-ielts-mobile-app";
export const ROUTE_MOBILE_APP_IOS = "/download-ielts-mobile-app/ios";
export const ROUTE_MOBILE_APP_HUAWEI = "/download-ielts-mobile-app/huawei";
export const ROUTE_MOBILE_APP_ANDROID = "/download-ielts-mobile-app/android";
export const ROUTE_PRACTICE_WRITING = "/practice?section=Writing&exercise=All";
export const ROUTE_PRACTICE_READING = "/practice?section=Reading&exercise=All";
export const ROUTE_PRACTICE_SPEAKING =
  "/practice?section=Speaking&exercise=All";
export const ROUTE_PRACTICE_LISTENING =
  "/practice?section=Listening&exercise=All";
export const ROUTE_PRACTICE_PREDICTION =
  "/practice?section=Reading&exercise=All&prediction=true";

export const ROUTE_HELP = "/help";
export const ROUTE_TERMS = "/regulations/terms-of-use";
export const ROUTE_WHY_US = "/why-us";
export const ROUTE_THANK_YOU = "/thank-you";
export const ROUTE_PRIVACY = "/regulations/privacy-policy";
export const ROUTE_ABOUT = "/about-us";

// Auth
export const SET_USER = "SET_USER";

// Snackbar notifs
export const GET_SNACKBAR = "GET_SNACKBAR";
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR";
export const SNACK_WRONG_PDF = "SNACK_WRONG_PDF";
export const SNACK_DEV_ERROR = "SNACK_DEV_ERROR";
export const SNACK_EMAIL_SENT = "SNACK_EMAIL_SENT";
export const SNACK_RESET_LINK = "SNACK_RESET_LINK";
export const SNACK_TEXT_COPIED = "SNACK_TEXT_COPIED";
export const SNACK_WRONG_IMAGE = "SNACK_WRONG_IMAGE";
export const SNACK_REWARD_CLAIM = "SNACK_REWARD_CLAIM";
export const SNACK_WRONG_FORMAT = "SNACK_WRONG_FORMAT";
export const SNACK_UPDATED_USER = "SNACK_UPDATED_USER";
export const SNACK_MULTI_ACCOUNT = "SNACK_MULTI_ACCOUNT";
export const SNACK_NOT_SCORECARD = "SNACK_NOT_SCORECARD";
export const SNACK_PROMO_EXPIRED = "SNACK_PROMO_EXPIRED";
export const SNACK_CRITICAL_ERROR = "SNACK_CRITICAL_ERROR";
export const SNACK_PASSWORD_RESET = "SNACK_PASSWORD_RESET";
export const SNACK_EMAIL_NOT_SENT = "SNACK_EMAIL_NOT_SENT";
export const SNACK_EMAIL_VERIFIED = "SNACK_EMAIL_VERIFIED";
export const SNACK_USER_NOT_FOUND = "SNACK_USER_NOT_FOUND";
export const SNACK_ACCOUNT_DELETED = "SNACK_ACCOUNT_DELETED";
export const SNACK_EMAIL_LIST_ADDED = "SNACK_EMAIL_LIST_ADDED";
export const SNACK_LEFT_TESTIMONIAL = "SNACK_LEFT_TESTIMONIAL";
export const SNACK_PAYMENT_CANCELLED = "SNACK_PAYMENT_CANCELLED";
export const SNACK_EMAIL_VERIFY_SENT = "SNACK_EMAIL_VERIFY_SENT";
export const SNACK_EMAIL_NOT_VERIFIED = "SNACK_EMAIL_NOT_VERIFIED";
export const SNACK_USER_TOKEN_EXPIRED = "SNACK_USER_TOKEN_EXPIRED";

// Bell notifs
export const NOTIF_NEW_REFERRAL = "NOTIF_NEW_REFERRAL";
export const NOTIF_DID_SUBSCRIBED = "NOTIF_DID_SUBSCRIBED";
export const NOTIF_BOUGHT_ADD_ON = "NOTIF_BOUGHT_ADD_ON";
export const NOTIF_SHOULD_FINISH_PROFILE = "NOTIF_SHOULD_FINISH_PROFILE";
export const NOTIF_ORIGINAL_PLATFORM = "NOTIF_ORIGINAL_PLATFORM";

export const NAVIGATE_TO = "NAVIGATE_TO";
export const CLEAR_NAVIGATE = "CLEAR_NAVIGATE";

// Maillist
export const MAILLIST_NEWSLETTER = "MAILLIST_NEWSLETTER";
export const SNACK_MAILLIST_NEWSLETTER = "SNACK_MAILLIST_NEWSLETTER";

// Url Params
export const PARAMS_ID = "id";
export const PARAMS_UID = "uid";
export const PARAMS_TOKEN = "token";
export const PARAMS_EMAIL = "email";
export const PARAMS_PASSWORD = "password";
export const PARAMS_SUBMIT = "submit";

// Timer is up
export const TIMER_BREAK = "TIMER_BREAK";
export const TIMER_SUBMIT = "TIMER_SUBMIT";
export const TIMER_SECTION = "TIMER_SECTION";
export const TIMER_MOCK_TEST = "TIMER_MOCK_TEST";

// Access
export const STUDENT = "student";

// Errors
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const QUICK_MODE = "QUICK_MODE";

// Payment
export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PROMOTIONS = "GET_PROMOTIONS";

// Exercises
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_PREDICTED_QUESTIONS = "GET_PREDICTED_QUESTIONS";
export const GET_ANALYSIS = "GET_ANALYSIS";
export const GET_PRACTICE_QUESTIONS = "GET_PRACTICE_QUESTIONS";
export const PRACTICE_DISPLAY = "PRACTICE_DISPLAY";
export const ESSAY_ANALYSED = "ESSAY_ANALYSED";
export const GET_SYNONYMS = "GET_SYNONYMS";
export const CLEAR_CURRENT_QUESTION = "CLEAR_CURRENT_QUESTION";
export const SET_ANALYSIS_SCORE = "SET_ANALYSIS_SCORE";
export const ANALYSIS_LOADING = "ANALYSIS_LOADING";
export const CLEAR_ANALYSIS_SCORE = "CLEAR_ANALYSIS_SCORE";

export const SET_PRACTICE = "SET_PRACTICE";
export const SET_DID_SUBMIT = "SET_DID_SUBMIT";
export const CLEAR_PRACTICE = "CLEAR_PRACTICE";
export const SET_USER_ANSWERS = "SET_USER_ANSWERS";
export const SET_QUESTION_DONE = "SET_QUESTION_DONE";
export const SET_PRACTICE_SCORE = "SET_PRACTICE_SCORE";
export const SET_REVIEW_ANSWERS = "SET_REVIEW_ANSWERS";
export const SET_PRACTICE_SCORE_DATA = "SET_PRACTICE_SCORE_DATA";
export const SET_MOCK_TEST_IS_UP = "SET_MOCK_TEST_IS_UP";
export const CLEAR_FOR_NEXT_QUESTION = "CLEAR_FOR_NEXT_QUESTION";
export const SET_SHOW_ANSWER = "SET_SHOW_ANSWER";
export const SET_MOCK_TEST_MODE = "SET_MOCK_TEST_MODE";
export const SET_REVIEW_MODE = "SET_REVIEW_MODE";
export const SET_PRACTICE_MOCK = "SET_PRACTICE_MOCK";
export const SET_MOCK_TEST_MODE_ONLY = "SET_MOCK_TEST_MODE_ONLY";
export const SET_READY_TO_SUBMIT = "SET_READY_TO_SUBMIT";

// Profile
export const IMAGE_UPLOAD_AND_SAVED_SUCCESSFULLY =
  "IMAGE_UPLOAD_AND_SAVED_SUCCESSFULLY";
export const STOP_LOADING = "STOP_LOADING";
export const TOGGLE_NIGHT_MODE = "TOGGLE_NIGHT_MODE";

// Testimonial
export const SUBMIT_TESTIMONIAL = "SUBMIT_TESTIMONIAL";
export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const CLEAR_TESTIMONIALS = "CLEAR_TESTIMONIALS";

// Loading global
export const BEGIN_LOADING = "BEGIN_LOADING";
export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

// Mock test
export const GET_MOCK_TESTS = "GET_MOCK_TESTS";

export const SET_MOCK_TEST = "SET_MOCK_TEST";
export const SET_MOCK_CURRENT_QUESTION = "SET_MOCK_CURRENT_QUESTION";
export const MOCK_TEST_DISPLAY = "MOCK_TEST_DISPLAY";
export const MOCK_TEST_EXAMPLE = "mock_test_example";
export const REVIEW_TEST_DISPLAY = "REVIEW_TEST_DISPLAY";
export const DISPLAY_ALL_MOCK_TEST = "DISPLAY_ALL_MOCK_TEST";
export const CLEAR_MOCK_TEST = "CLEAR_MOCK_TEST";
export const FETCH_MOCK_TEST_SCORES = "FETCH_MOCK_TEST_SCORES";

// Blog
export const GET_ARTICLES = "GET_ARTICLES";
export const GET_ARTICLE_META = "GET_ARTICLE_META";
export const GET_BLOGS = "GET_BLOGS";

// Statistics
export const GET_STATISTICS = "GET_STATISTICS";

// Recorder
export const PLAYER_DID_FINISH = "PLAYER_DID_FINISH";
export const CLEAR_RECORDER = "CLEAR_RECORDER";
export const RECORDER_TOGGLE_RECORDING = "RECORDER_TOGGLE_RECORDING";
export const RECORDER_IS_RECORDING = "RECORDER_IS_RECORDING";
export const RECORDER_REVIEW_MODE = "RECORDER_REVIEW_MODE";
export const RECORDER_DID_FINISH = "RECORDER_DID_FINISH";
export const RECORDER_ARTYOM_END = "RECORDER_ARTYOM_END";
export const RECORDER_TIME_WAITING = "RECORDER_TIME_WAITING";
export const RECORDER_TIME_ANSWERING = "RECORDER_TIME_ANSWERING";
export const RECORDER_SILENCE_DETECTED = "RECORDER_SILENCE_DETECTED";
export const RECORDER_ACCESS_DENIED = "RECORDER_ACCESS_DENIED";
export const SET_SPEECH_TEXT = "SET_SPEECH_TEXT";
export const FORCE_SHOW_ANSWER = "FORCE_SHOW_ANSWER";
export const DID_ACTIVATE_SIRI = "DID_ACTIVATE_SIRI";
export const SET_BLOB_URL = "SET_BLOB_URL";
export const SET_BLOB_URL_OVERVIEW = "SET_BLOB_URL_OVERVIEW";

// Promo
export const SHOWED_PROMO = "SHOWED_PROMO";

// Maillist
export const MAILLIST_PAID = "MAILLIST_PAID";
export const MAILLIST_REGISTERED = "MAILLIST_REGISTERED";

// Categories
export const CATEGORY_READING = "IELTS Reading";
export const CATEGORY_LISTENING = "IELTS Listening";
export const CATEGORY_SPEAKING = "IELTS Speaking";
export const CATEGORY_WRITING = "IELTS Writing";
export const CATEGORY_GENERAL = "IELTS General";

// Sections
export const FULL = "Full";
export const CEFR = "Cefr";
export const READING = "Reading";
export const LISTENING = "Listening";
export const SPEAKING = "Speaking";
export const WRITING = "Writing";
export const OVERALL = "Overall";
export const RESOURCES = "Resources";
export const ACADEMIC = "Academic";
export const GENERAL = "General";

// Exercises
export const SPEAKING_SHORT_ANSWER = "Answer Short Question";
export const SPEAKING_REPEAT_SENTENCE = "Repeat Sentence";
export const SPEAKING_READ_ALOUD = "Read Aloud";
export const SPEAKING_DESCRIBE_IMAGE = "Describe Image";
export const SPEAKING_RETELL_LECTURE = "Re-tell Lecture";

export const READING_SINGLE = "READING_SINGLE";
export const READING_MULTIPLE = "READING_MULTIPLE";
export const READING_REORDER = "READING_REORDER";
export const READING_FILL_BLANK = "READING_FILL_BLANK";
export const READING_WRITING_FILL_BLANK = "READING_WRITING_FILL_BLANK";

// Steps done
export const STEPS_DID_ONBOARDING = "did_onboarding";
export const STEPS_DID_ONBOARDING_PRACTICE = "did_onboarding_pratice";
export const STEPS_VIEW_REWARD = "did_view_reward";
export const STEPS_ACCESS_BLOCKED = "access_blocked";
export const STEPS_WANT_BG_NOISE = "want_bg_noise";
export const STEPS_SEE_DETAILS = "did_view_score_details";
export const STEPS_SEE_SKILL = "did_view_official_skill";
export const STEPS_AUTO_NIGHT = "should_auto_night_mode";
export const STEPS_EMAIL_GENERAL = "can_send_email_general";
export const STEPS_DID_CLAIM_REWARD = "did_claim_reward";
export const STEPS_SHOULD_HIDE_TASK = "should_hide_task";
export const STEPS_SHOULD_TESTIMONIAL = "should_leave_testimonial";
export const STEPS_LEFT_TESTIMONIAL = "did_leave_testimonial";
export const STEPS_CAN_TAKE_MOCK_TEST_1 = "can_take_mock_test_1";
export const STEPS_CAN_SHARE_TO_GET_TEMPLATES = "can_share_to_get_templates";
export const STEPS_DID_VERIFY_EMAIL = "did_verify_email";
export const STEPS_DID_SEND_VERIFY_EMAIL = "did_send_verify_email";
export const STEPS_DID_ACTIVATE_SIRI = "did_activate_siri";
export const STEPS_SHOULD_SET_PASSWORD = "should_set_password";

// Localstorage
export const ROWS_PER_PAGE = "rowsPerPage";
export const IS_NIGHT_MODE = "isNightMode";
export const JWT_TOKEN = "jwtToken";
export const REFERRAL_ID = "referralId";
export const COUPON_ID = "couponId";
export const VOLUME = "volume";
export const LOCATION = "location";
export const PREDICTION_MODE = "predictionMode";
export const FAVORITE_MODE = "favoriteMode";
export const FREE_WRITING_CREDITS = "freeWritingCredits";
export const FREE_SPEAKING_CREDITS = "freeSpeakingCredits";
export const HAS_CPC_OFFER = "cpcOffer";
export const HAS_CPC_OFFER_ALL = "cpcOfferAll";
export const HAS_REGISTERED_ONCE = "hasRegisteredOnce";
export const HAS_CLOSED_BANNER = "hasClosedBanner";
export const HAS_SHOWN_OVERVIEW = "hasShownOverview";

// Enbling writing skills
export const ENABLING_TASK = "Task achievement";
export const ENABLING_COHERENCE = "Coherence and cohesion";
export const ENABLING_LEXICAL = "Lexical resource";
export const ENABLING_GRAMMAR = "Grammatical range and accuracy";
export const ENABLING_FINAL = "Final";
export const ENABLING_FORBIDDEN_WORDS = "has_forbidden_words";
export const ENABLING_MISSING_LETTER_WORDS = "missing_letter_words";
export const ENABLING_HAS_POWERFULL_WORDS = "has_powerfull_words";

export const ENABLING_FORM = "Form";
export const ENABLING_VOC = "Vocabulary";
export const ENABLING_SPELLING = "Spelling";
export const ENABLING_CONTENT = "Content";
export const ENABLING_PRONUNCIATION = "Pronunciation";
export const ENABLING_FLUENCY = "Fluency and coherence";
export const ENABLING_DSC = "Development, structure and coherence";
export const ENABLING_GLR = "General linguistic range";

export const ENABLING_DISCOURSE = "Written Discourse";

///////////////
// FRAGMENTS //
///////////////

export const LISTING = "LISTING";
export const STATISTICS = "STATISTICS";
export const SETTINGS = "SETTINGS";
export const CONTACT_US = "CONTACT_US";

///////////////
//  BROWSERS //
///////////////

export const CHROME = "Chrome";
export const SAFARI = "Safari";

////////////////
//  PACKAGES  //
////////////////
export const FREE = "package_free";
export const SILVER = "package_silver";
export const SILVER_DISCOUNT = "package_silver_discount";
export const BRONZE = "package_bronze";
export const BRONZE_DISCOUNT = "package_bronze_discount";
export const BRONZE_DISCOUNT_SECT = "package_bronze_sect";
export const ULTIMATE = "package_ultimate";
export const ULTIMATE_DISCOUNT = "package_ultimate_discount";
export const EXTENSION = "package_extend";
export const SPEAKING_CREDITS = "package_speaking_credits";
export const COMBO = "package_combo";
export const TEMPLATE_PACKAGE = "templates_pack";

export const FULL_MOCK_PACKAGE = "package_full_mock";
export const FULL_MOCK_PACKAGE_2 = "package_full_mock_2";

export const SECT_MOCK_PACKAGE = "package_sect_mock";
export const SECT_MOCK_PACKAGE_2 = "package_sect_mock_2";

export const FULL_MOCK_UPSELL = "full_mock_test_upsell";
export const SECTIONAL_MOCK_UPSELL = "mock_test_sectional_upsell";

////////////////
//  PAYMENTS  //
////////////////
export const VISA = "card";
export const PAYPAL = "paypal";
export const ALIPAY = "alipay";
export const APPLEPAY = "apple pay";
export const GOOGLEPAY = "google pay";
export const BANCONTACT = "bancontact";

////////////////
// HOME COMPONENTS LAYOUT KEYS
////////////////
export const HOME_HERO_SECTION = "HOME_HERO_SECTION";
export const HOME_GRID_SECTION = "HOME_GRID_SECTION";
export const HOME_SLIDER_SECTION = "HOME_SLIDER_SECTION";
export const HOME_TESTIMONIAL_SECTION = "HOME_TESTIMONIAL_SECTION";
export const HOME_FAQ_SECTION = "HOME_FAQ_SECTION";
export const HOME_BUY_SECTION = "HOME_BUY_SECTION";
export const HOME_LAST_SECTION = "HOME_LAST_SECTION";
export const HOME_TWO_COL_IMAGE_SECTION = "HOME_TWO_COL_IMAGE_SECTION";
export const HOME_THREE_COL_SECTION = "HOME_THREE_COL_SECTION";
export const HOME_THREE_COL_IMAGE_SECTION = "HOME_THREE_COL_IMAGE_SECTION";
export const HOME_VIEWER_SECTION = "HOME_VIEWER_SECTION";
export const HOME_BLOG_SECTION = "HOME_BLOG_SECTION";
export const HOME_BANNER_SECTION = "HOME_BANNER_SECTION";
export const HOME_VIDEO_TESTIMONIAL_SECTION = "HOME_VIDEO_TESTIMONIAL_SECTION";
export const HOME_TWO_VIDEO_TESTIMONIAL_SECTION =
  "HOME_TWO_VIDEO_TESTIMONIAL_SECTION";
export const HOME_TEMPLATES_SECTION = "HOME_TEMPLATES_SECTION";

// Review
export const SET_USERS_TESTIMONIAL = "SET_USERS_TESTIMONIAL";

// STRIPE
export const STRIPE_PK_KEY_TEST =
  "pk_test_51DIrNcA8CAm8eTMDCDilBMngGt5nglmLUFUTJmV4OtkMJq1qJIBzmqaEwrLG54UTanWOoA8E5p9gGvmH0NT3UxrP00dN77oDxK";
export const STRIPE_PK_KEY_LIVE = "pk_live_0ekQZ5owh1dXQcJh1GHlSkL6";

// PAYPAL
export const PAYPAL_ID_TEST =
  "AfUn5w5gXUgveoz4AT42tnA-4KmKXPYw3lPtHsWZo_rgO1fpRn7WhUfchxboubvTvJc0eCW4LKW3JZJO";
export const PAYPAL_ID_LIVE =
  "AWxi8nYUzkiWiPtG0kiQp4F6kQ92AgZbL9vF1rj6FyoDMwH-XclT9jWLD7V6yNKYaR42uSwf_uA2sK5I";

// CAPTCHA
export const RECAPTCHA_SITE_KEY = "6Lfy-mMiAAAAAPJhgD_BxR6KVSX0JpH1HjrKWNrM";
