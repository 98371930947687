import axios from "axios";

import { handleErrors } from "./errorActions";
import { updateUser, useWritingCredit } from "./userActions";

import {
  GET_ANALYSIS,
  SET_DID_SUBMIT,
  ANALYSIS_LOADING,
  SET_ANALYSIS_SCORE,
  CLEAR_ANALYSIS_SCORE,
} from "../helpers/types";

// @desc  Analize writing answer for Writing/Listening exercises
// @next  Load analysis to redux
export const analyseWritingText = (data) => (dispatch) => {
  dispatch(analyseAlertLoading(true));
  axios
    .post("/api/analysis/analyseWritingText", data)
    .then((res) => {
      console.log(res.data);

      dispatch(setAnalysis(res.data));

      if (!data.isMockTest) {
        dispatch(useWritingCredit({ isAuthenticated: data.isAuthenticated }));
      } else {
        dispatch(updateUser({}));
        // Needed to refresh user (in case timerIsUp)
      }
    })
    .catch((error) => {
      dispatch(handleErrors(error));
      dispatch(analyseAlertLoading(false));
      dispatch({ type: SET_DID_SUBMIT, payload: false });
    });
};

// @desc  Analize writing loading
export const analyseAlertLoading = (data) => (dispatch) => {
  dispatch({ type: ANALYSIS_LOADING, payload: data });
};

// @desc  Get word definition
// @next  Return word details
export const getWordInfo = (word) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/analysis/getWordInfo", word)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// @desc  Get user writings
// @next  Return writings and scores
export const getUserWritings = (data) => () => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/analysis/getUserWritings", data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// @desc  Save user writings
// @next  Return writings and scores
export const saveUserWriting = (data) => (dispatch) => {
  axios.post("/api/analysis/saveUserWriting", data).catch((error) => {
    dispatch(handleErrors(error));
  });
};

// @desc  Save analysis
export const setAnalysis = (data) => (dispatch) => {
  dispatch({ type: GET_ANALYSIS, payload: data });
};

// @desc  Save analysis score
export const setAnalysisScore = (data) => (dispatch) => {
  dispatch({ type: SET_ANALYSIS_SCORE, payload: data });
};

// @desc  Clear analysis reducer
export const clearAnalysisScore = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ANALYSIS_SCORE, payload: data });
};

// @desc  Analyse Speaking Intro
// @next  Load analysis to redux
export const getScoreForSpeakingIntro = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/analysis/getScoreForSpeakingIntro", data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// @desc  Analyse Speaking Intro
// @next  Load analysis to redux
export const getScoreForSpeakingCueCard = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/analysis/getScoreForSpeakingCueCard", data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
