import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";

import TextFieldGroup from "../common/TextFieldGroup";

import {
  ROUTE_PRACTICE,
  ROUTE_STATISTICS,
  STEPS_LEFT_TESTIMONIAL,
  STEPS_SHOULD_TESTIMONIAL,
} from "../../helpers/types";
import { doesUserHave } from "../../helpers/userCheck";
import getRandomFromArray from "../../helpers/getRandomFromArray";

import {
  leaveTestimonial,
  refuseTestimonial,
  postponeTestimonial,
} from "../../actions/userActions";
import { clearErrors } from "../../actions/errorActions";

// List of URL to trigger alert
const triggerUrl = [ROUTE_STATISTICS, ROUTE_PRACTICE];

// TODO ask rating first => collect 5 stars and send truspilot invite
// then optional title and message to leave

// Modal alert to leave testimonial
function AlertTestimonial(props) {
  const classes = useStyles();

  const { user, errors } = props;
  const { pathname } = props.location;
  const titleSuggestions = [
    // Will pick random title suggestion
    "Great platform!",
    "Love it!",
    "Very helpful",
    "Well organized",
    "Easy to use!",
    "Great material!",
    "Best IELTS website!",
  ];

  const [quote, setQuote] = useState("");
  const [rating, setRating] = useState(0);
  const [open, setOpen] = useState(false);
  const [header, setHeader] = useState("");

  // Clear previous errors when typing
  useEffect(() => {
    props.clearErrors();
  }, [quote, header]);

  // Check if display modal
  useEffect(() => {
    if (
      doesUserHave(user, STEPS_SHOULD_TESTIMONIAL) &&
      !doesUserHave(user, STEPS_LEFT_TESTIMONIAL) &&
      triggerUrl.includes(pathname)
    ) {
      if (user.postponeReview < Date.now()) {
        setOpen(true);
      }
    }
  }, [user, pathname]);

  // Save new testimonial
  async function leaveTestimonial() {
    const data = { header, quote, rating };
    await props.leaveTestimonial(data);
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you like IELTS Success?
        </DialogTitle>
        <DialogContent style={{ width: "30rem", maxWidth: "70vw" }}>
          <DialogContentText id="alert-dialog-description">
            Let others know what you think about the platform!
            {/* TODO */}
          </DialogContentText>
          <div className={classes.rating}>
            <Rating
              name="hover-feedback"
              value={rating}
              style={{ fontSize: "3rem" }}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
          </div>
          <TextFieldGroup
            label="Header"
            name="header"
            value={header}
            onChange={(e) => setHeader(e.target.value)}
            error={errors.header}
            shrinkLabel={true}
            placeholder={getRandomFromArray(titleSuggestions)}
            info="Title must be between 5 and 20 characters"
            onKeyPress={leaveTestimonial}
          />
          <TextFieldGroup
            label="Message"
            value={quote}
            onChange={(e) => setQuote(e.target.value)}
            error={errors.quote}
            rows={8}
            multiline={true}
            shrinkLabel={true}
            placeholder="Tell us what you like about the platform..."
            info="250 characters max."
            onKeyPress={leaveTestimonial}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1 }}>
            <Button
              onClick={() => {
                setOpen(false);
                props.refuseTestimonial();
              }}
              variant="outlined"
              color="secondary"
            >
              No
            </Button>
          </div>
          <Button
            onClick={() => {
              setOpen(false);
              props.postponeTestimonial();
            }}
            variant="contained"
            color="secondary"
          >
            Later
          </Button>
          <Button
            onClick={leaveTestimonial}
            variant="contained"
            color="primary"
            disabled={rating === 0}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  rating: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const mapStateToProps = (state) => ({
  errors: state.errors,
  user: state.user,
});

export default connect(mapStateToProps, {
  clearErrors,
  leaveTestimonial,
  refuseTestimonial,
  postponeTestimonial,
})(withRouter(AlertTestimonial));
