import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import {
  ROUTE_EXERCISE,
  ROUTE_PRACTICE,
  ROUTE_MOCK_TEST,
  ROUTE_PRACTICE_READING,
  ROUTE_PRACTICE_WRITING,
  ROUTE_PRACTICE_SPEAKING,
  ROUTE_PRACTICE_LISTENING,
} from "../../helpers/types";
import isEmpty from "../../helpers/isEmpty";
import { checkAppVersion, updateAppVersion } from "../../actions/userActions";

// List of urls to prevent alert
const dontTriggerUrl = [
  ROUTE_MOCK_TEST,
  ROUTE_PRACTICE,
  ROUTE_EXERCISE,
  ROUTE_PRACTICE_READING,
  ROUTE_PRACTICE_WRITING,
  ROUTE_PRACTICE_SPEAKING,
  ROUTE_PRACTICE_LISTENING,
];

// Modal alert to force platform update
function AlertUpdateApp(props) {
  const { user } = props;
  const [open, setOpen] = useState(false);

  // Check if update needed on allowed urls
  useEffect(() => {
    if (!isEmpty(user) && !dontTriggerUrl.includes(window.location.pathname)) {
      checkAppVersion();
    }
  }, [user]);

  // Trigger alert if update needed
  async function checkAppVersion() {
    const shouldUpdate = await props.checkAppVersion(user.version);
    setOpen(shouldUpdate);
  }

  // Trigger update user version + close alert
  function updateAppVersion() {
    props.updateAppVersion();
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"New features available"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update to get the latest tools and content.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={updateAppVersion}
            variant="contained"
            color="secondary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { checkAppVersion, updateAppVersion })(
  AlertUpdateApp
);
