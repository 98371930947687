import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Rating from "@material-ui/lab/Rating";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import { HAS_CLOSED_BANNER } from "../../helpers/types";
import { isAuthenticated } from "../../helpers/userCheck";
import getPlatformLayout from "../../helpers/getPlatformLayout";
import saveToLocalStorage from "../../helpers/saveToLocalStorage";
import getMobileOperatingSystem from "../../helpers/getMobileOperatingSystem";

// Custom mobile app banner
function MobileAppBanner(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(
    !isAuthenticated(props.user) && !localStorage[HAS_CLOSED_BANNER]
  );

  // Hide on login
  useEffect(() => {
    if (isAuthenticated(props.user)) {
      setOpen(false);
    }
  }, [props.user]);

  // Close banner and remember choice
  function closeMobileAppBanner() {
    saveToLocalStorage(HAS_CLOSED_BANNER, "true");
    setOpen(false);
  }

  return (
    <Slide in={open} mountOnEnter unmountOnExit timeout={1000} direction="up">
      <Grid className={classes.wrapper}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => closeMobileAppBanner()}
        />
        <Avatar
          className={classes.logo}
          src={getPlatformLayout({ type: "logo" })}
          alt="ielts success - mobile app banner"
        />

        <div style={{ flexGrow: 1 }}>
          <Typography className={classes.title}>
            {`IELTS Success for ${getMobileOperatingSystem()}`}
          </Typography>
          <div className={classes.rating}>
            <Rating
              readOnly
              value={4.7}
              precision={0.1}
              name="read-only"
              style={{ fontSize: "1.5em" }}
            />
          </div>
        </div>
        <div className={classes.installWrapper}>
          <a
            rel="noopener"
            target="_blank"
            style={{ textDecoration: "none" }}
            href="http://onelink.to/ielts_prep"
          >
            <Typography className={classes.install}>Install</Typography>
          </a>
        </div>
      </Grid>
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    left: 0,
    bottom: 0,
    zIndex: 2000,
    width: "100%",
    display: "flex",
    position: "fixed",
    alignItems: "center",
    boxShadow: theme.shadow.boxLight,
    background: theme.palette.background.paper,
    border: `solid 1px ${theme.palette.grey[200]}`,
  },
  closeIcon: {
    padding: "0.2em",
    color: theme.palette.grey[500],
    "&:hover ": {
      cursor: "pointer",
      transform: "scale(1.01)",
      opacity: theme.customVars.hoverOpacity,
    },
  },
  logo: {
    width: "49px",
    margin: "7px",
    height: "49px",
  },
  icon: {
    display: "flex",
    fontSize: "2em",
    padding: "0.3em",
    borderRadius: "5em",
    marginRight: "0.5em",
    color: theme.customColors.red,
    background: theme.customColors.redLight,
    [theme.breakpoints.down("sm")]: {
      marginRight: "0.5em",
    },
  },
  title: {
    fontSize: "1.1em",
    fontWeight: "bold",
    color: theme.palette.grey[600],
  },
  rating: {
    display: "flex",
    alignItems: "center",
  },
  installWrapper: {
    display: "flex",
    placeContent: "center",
  },
  install: {
    width: "auto",
    color: "white",
    fontSize: "15pt",
    cursor: "pointer",
    fontWeight: "bold",
    borderRadius: "5px",
    marginRight: "0.5em",
    background: "#60a200",
    padding: "0.2em 0.5em",
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(MobileAppBanner);
