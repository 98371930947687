import { GET_MOCK_TESTS } from "../helpers/types";

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MOCK_TESTS:
      return action.payload;

    default:
      return state;
  }
}
