import Bowser from "bowser";
var browser = window.navigator.userAgent;

// Return details about the browser
const getBrowserDetails = () => {
  return Bowser.parse(browser);
};

// Return details about the browser
const getBrowserData = () => {
  return Bowser.parse(browser).browser;
};

// Browsers with Artyom Speech recognition support
const isArtyomBrowser = () => {
  return true;
  
  return (
    Bowser.getParser(browser).satisfies({
      // Desktop only
      desktop: {
        chrome: ">=33",
        // edge: ">=79", // Seems like it's not working anymore
        // No Firefox
        // No Safari
        // No Opera
      },
    }) || Bowser.parse(browser)?.os?.name === "Chrome OS" // Allow Chromebooks
  );
};

// Browsers with Siri Speech recognition support
const isSafariSpeechBrowser = () => {
  return Bowser.getParser(browser).satisfies({
    safari: ">=14.1",
  });
};

export {
  getBrowserData,
  isArtyomBrowser,
  getBrowserDetails,
  isSafariSpeechBrowser,
};
