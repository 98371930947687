import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Global loading spinner
function SpinnerLoading(props) {
  const classes = useStyles();

  return props.loading ? (
    <div className={classes.loadingWrapper}>
      <CircularProgress
        size={70}
        thickness={5}
        disableShrink
        className={classes.loading}
      />
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  loading: state.loading,
});

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    top: 0,
    left: 0,
    zIndex: 9999,
    width: "100%",
    height: "100%",
    display: "flex",
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    background:
      theme.palette.type === "light"
        ? "rgba(0, 0, 0, 0.2)"
        : "rgba(255, 255, 255, 0.1)",
  },
  loading: {
    color: theme.customColors.blue,
  },
}));

export default connect(mapStateToProps)(SpinnerLoading);
