import { GET_QUESTIONS } from "../helpers/types";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUESTIONS:
      return state.concat(action.payload);

    default:
      return state;
  }
}
