import {
  compose,
  applyMiddleware,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./";
import { CHROME } from "../helpers/types";
import { getBrowserData } from "../helpers/getBrowserData";

const initialState = {};

const middleware = [thunk];
var store;

// Dev mode -> See redux store in Chrome
if (getBrowserData().name === CHROME && process.env.NODE_ENV !== "production") {
  store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
} else {
  // Prod mode
  store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
  );
}

export default store;
