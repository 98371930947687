import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import getNavbar from "../../helpers/getNavbar";
import { ROUTE_SIGNUP } from "../../helpers/types";
import { navigateTo } from "../../actions/navigationActions";

function NavbarCollapsedMenu(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function openMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function goTo(toLink) {
    setAnchorEl(null);
    props.navigateTo(toLink);
  }

  return (
    <div className={classes.linkWrapper}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={openMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: 200 } }}
      >
        {getNavbar.map((item) => {
          const { name, toLink } = item;
          const isSelected = window.location.pathname === toLink;
          return (
            <Typography
              key={name}
              selected={isSelected}
              onClick={() => goTo(toLink)}
              className={clsx(classes.link, {
                [classes.linkSelected]: isSelected,
                [classes.linkHighlight]: toLink === ROUTE_SIGNUP,
              })}
            >
              {name}
            </Typography>
          );
        })}
      </Menu>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    flexGrow: 1,
    display: "none",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  link: {
    display: "flex",
    minWidth: "4em",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 1),
    margin: theme.spacing(0.5, 0.5),
    "&:hover ": {
      color: "black",
      borderRadius: "2em",
      backgroundColor: theme.palette.action.hover,
    },
  },
  linkSelected: {
    color: "white",
    borderRadius: "2em",
    background: theme.customColors.red,
  },
  linkHighlight: {
    borderRadius: "2em",
    border: `2px solid ${theme.customColors.blue}`,
  },
}));

export default connect(null, { navigateTo })(NavbarCollapsedMenu);
