import React from "react";

import SearchIcon from "@material-ui/icons/Search";
import PracticeIcon from "@material-ui/icons/ListAlt";
import MobileAppIcon from "@material-ui/icons/GetApp";
import BlogsIcon from "@material-ui/icons/LibraryBooks";
import MockTestIcon from "@material-ui/icons/LaptopMac";
import DashboardIcon from "@material-ui/icons/Dashboard";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import {
  ROUTE_HELP,
  ROUTE_BLOG,
  ROUTE_PRACTICE,
  ROUTE_DASHBOARD,
  ROUTE_MOCK_TEST,
  ROUTE_STATISTICS,
  ROUTE_MOBILE_APP,
} from "./types";

// List icons in side drawer for authenticated users
const getSideDrawer = [
  {
    name: "Dashboard",
    toLink: ROUTE_DASHBOARD,
    icon: <DashboardIcon />,
  },
  {
    name: "Quick Practice",
    toLink: ROUTE_PRACTICE,
    icon: <PracticeIcon />,
  },
  {
    name: "Mock Tests",
    toLink: ROUTE_MOCK_TEST,
    icon: <MockTestIcon />,
  },
  {
    name: "Statistics",
    toLink: ROUTE_STATISTICS,
    icon: <InsertChartIcon />,
  },
  {
    name: "Blogs",
    toLink: ROUTE_BLOG,
    icon: <BlogsIcon />,
  },
  {
    name: "FAQ",
    toLink: ROUTE_HELP,
    icon: <SearchIcon />,
  },
  {
    name: "Mobile App",
    toLink: ROUTE_MOBILE_APP,
    icon: <MobileAppIcon />,
  },
];

export default getSideDrawer;
