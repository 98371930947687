import { START_LOADING, FINISH_LOADING } from "../helpers/types";

// @desc  Start global loading spinner (gif)
export const startLoadingSpinner = () => {
  return { type: START_LOADING };
};

// @desc  Stop global loading spinner (gif)
export const stopLoadingSpinner = () => {
  return { type: FINISH_LOADING };
};
