import React from "react";

import Avatar from "@material-ui/core/Avatar";
import getPlatformLayout from "./getPlatformLayout";

import SearchIcon from "@material-ui/icons/Search";
import PracticeIcon from "@material-ui/icons/ListAlt";
import MobileAppIcon from "@material-ui/icons/GetApp";
import GiftIcon from "@material-ui/icons/CardGiftcard";
import BlogsIcon from "@material-ui/icons/LibraryBooks";
import MockTestIcon from "@material-ui/icons/LaptopMac";
import DashboardIcon from "@material-ui/icons/Dashboard";
import InsertChartIcon from "@material-ui/icons/InsertChart";

import { ROUTE_PRACTICE, STEPS_DID_ONBOARDING_PRACTICE } from "./types";

// Onboarding steps cutout position
const getOnBoardingCutouts = ({ step }) => {
  if (step === 1) {
    return {
      top: "63px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "You can access your dashboard with this icon.",
      icon: <DashboardIcon />,
    };
  } else if (step === 2) {
    return {
      top: "113px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "You can practice each IELTS task in this section.",
      icon: <PracticeIcon />,
    };
  } else if (step === 3) {
    return {
      top: "163px",
      left: "0px",
      width: "65px",
      height: "65px",
      description:
        "Here is the mock test section with your results and scorecards.",
      icon: <MockTestIcon />,
    };
  } else if (step === 4) {
    return {
      top: "213px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Check your progress and study plan in this section.",
      icon: <InsertChartIcon />,
    };
  } else if (step === 5) {
    return {
      top: "263px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Find tips and articles from IELTS teachers and experts.",
      icon: <BlogsIcon />,
    };
  } else if (step === 6) {
    return {
      top: "313px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "No sure how IELTS works? Use this icon.",
      icon: <SearchIcon />,
    };
  } else if (step === 7) {
    return {
      top: "363px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Download the IELTS Success mobile app.",
      icon: <MobileAppIcon />,
    };
  } else if (step === 8) {
    return {
      top: "0px",
      right: "12px",
      width: "65px",
      height: "65px",
      description: "More options and night mode are right there.",
      icon: (
        <Avatar
          alt="ielts website login"
          src={
            "https://pineapplestudio.com.au/ielts_success/images/avatars/avatar_default.png"
          }
        />
      ),
    };
  } else if (step === 9) {
    return {
      top: "0px",
      left: "0px",
      width: "63px",
      height: "63px",
      description: "Click the logo at anytime to go back to the Dashboard.",
      icon: (
        <Avatar
          alt="ielts success - online practice free"
          style={{ width: "49px", height: "49px" }}
          src={getPlatformLayout({ type: "logo" })}
        />
      ),
    };
  } else if (step === 10) {
    return {
      bottom: "0px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Get rewards by inviting your friends!",
      icon: <GiftIcon />,
    };
  } else {
    return {
      top: "0px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Practice individual exercises here.",
      icon: <DashboardIcon />,
    };
  }
};

// Onboarding with large images
const getOnBoardingLarge = (pathname) => {
  if (pathname === ROUTE_PRACTICE) {
    return {
      steps: [
        "Every sections",
        "Select an exercise",
        "Real exam questions",
        "Prediction questions",
        "Your favorites",
      ],
      title: "Get the most from your practice",
      descriptions: [
        "Select which section to practice.",
        "Choose an exercise in the list.",
        "See how many times this question appeared in exam.",
        "Display the questions most likely to appear now in the IELTS exam.",
        "Click this icon to display your saved favorite questions.",
      ],
      images: [
        "onboarding_ielts_success_1",
        "onboarding_ielts_success_2",
        "onboarding_ielts_success_3",
        "onboarding_ielts_success_4",
        "onboarding_ielts_success_5",
      ],
      onboardingKey: STEPS_DID_ONBOARDING_PRACTICE,
    };
  }
};

export { getOnBoardingCutouts, getOnBoardingLarge };
