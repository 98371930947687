import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import CutoutBox from "../onBoarding/CutoutBox";
import OnBoardingMessageBox from "../onBoarding/OnBoardingMessageBox";

import {
  STEPS_DID_ONBOARDING,
  STEPS_SHOULD_SET_PASSWORD,
} from "../../helpers/types";
import { isAuthenticated } from "../../helpers/userCheck";
import { updateUserArray } from "../../actions/userActions";
import { getOnBoardingCutouts } from "../../helpers/getOnBoarding";

// Onboarding first time use app (cutouts version)
function OnBoardingSmall(props) {
  const classes = useStyles();
  const { user } = props;
  const { stepsDone } = props.user;
  const largeDevice = useMediaQuery("(min-width:900px)");

  const [step, setStep] = useState(1);

  const cutout = getOnBoardingCutouts({ step });

  // Last step -> Save as done
  useEffect(() => {
    if (step > 10) {
      props.updateUserArray({ stepsDone: STEPS_DID_ONBOARDING });
    }
  }, [step]);

  // Prevent rendering if done
  if (
    !largeDevice || // Hide on phone
    !isAuthenticated(user) ||
    stepsDone.includes(STEPS_DID_ONBOARDING) ||
    stepsDone.includes(STEPS_SHOULD_SET_PASSWORD) ||
    step > 10
  )
    return null;

  return (
    <div className={classes.overlay}>
      <CutoutBox className={classes.cutoutBox} cutout={cutout} />
      <OnBoardingMessageBox
        cutout={cutout}
        onNext={() => setStep((step) => step + 1)}
        onPrevious={() => setStep((step) => (step > 1 ? step - 1 : 1))}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  overlay: {
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    position: "fixed",
    display: "flex",
    background: "#00000091",
    zIndex: "10000",
  },
  cutoutBox: {
    background: "#ffffff",
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.customColors.blue,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { updateUserArray })(OnBoardingSmall);
