import {
  ROUTE_HOME,
  ROUTE_BLOG,
  ROUTE_LOGIN,
  ROUTE_SIGNUP,
  ROUTE_PRACTICE,
  ROUTE_MOBILE_APP,
} from "./types";

// List navbar links for public users
const getNavbar = [
  {
    name: "Home",
    toLink: ROUTE_HOME,
  },
  {
    name: "Practice",
    toLink: ROUTE_PRACTICE,
  },
  {
    name: "Mock Tests",
    toLink: "/mock-test",
  },
  {
    name: "Blog",
    toLink: ROUTE_BLOG,
  },
  {
    name: "IELTS App",
    toLink: ROUTE_MOBILE_APP,
  },
  {
    name: "Login",
    toLink: ROUTE_LOGIN,
  },
  {
    name: "Sign up Free",
    toLink: ROUTE_SIGNUP,
  },
];

export default getNavbar;
