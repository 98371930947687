import React from "react";
import { connect } from "react-redux";

import { withTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import FaceIcon from "@material-ui/icons/Face";
import ContactIcon from "@material-ui/icons/Send";
import ExitIcon from "@material-ui/icons/ExitToApp";
import GiftIcon from "@material-ui/icons/CardGiftcard";

import {
  ROUTE_USER,
  ROUTE_REWARD,
  ROUTE_CONTACT_US,
} from "../../helpers/types";
import getSideDrawer from "../../helpers/getSideDrawer";
import { logoutUser } from "../../actions/userActions";
import { navigateTo } from "../../actions/navigationActions";

// Drop down menu from User avatar
function UserIconMenu({ anchorEl, ...props }) {
  const { red, blue } = props.theme.customColors;
  const largeDevice = useMediaQuery("(min-width:900px)");

  // Navigate to link
  function goTo(link) {
    props.navigateTo(link);
    handleClose();
  }

  // Tell parent to close menu
  function handleClose() {
    props.handleClose();
  }

  return (
    <Menu
      elevation={0}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          border: "1px solid #d3d4d5",
        },
      }}
    >
      <ClickAwayListener onClickAway={() => handleClose}>
        <div>
          <MenuList>
            {/* Profile */}
            <MenuItem onClick={() => goTo(ROUTE_USER)}>
              <ListItemIcon style={{ color: blue }}>
                <FaceIcon />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            <Divider style={{ width: "96%", marginLeft: "2%" }} />
            {/* Menu items for mobile devices */}
            {!largeDevice &&
              getSideDrawer
                .concat({
                  name: "Reward",
                  toLink: ROUTE_REWARD,
                  icon: <GiftIcon />,
                })
                .map((item, i) => {
                  const { name, toLink, icon } = item;
                  return (
                    <MenuItem key={i} onClick={() => goTo(toLink)}>
                      <ListItemIcon style={{ color: blue }}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={name} />
                    </MenuItem>
                  );
                })}
            {/* Contact Us */}
            <MenuItem onClick={() => goTo(ROUTE_CONTACT_US)}>
              <ListItemIcon style={{ color: blue }}>
                <ContactIcon />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
            </MenuItem>
            <Divider style={{ width: "96%", marginLeft: "2%" }} />
            {/* Log Out */}
            <MenuItem onClick={() => props.logoutUser({})}>
              <ListItemIcon style={{ color: red }}>
                <ExitIcon />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </MenuItem>
          </MenuList>
        </div>
      </ClickAwayListener>
    </Menu>
  );
}

export default connect(null, {
  logoutUser,
  navigateTo,
})(withTheme(UserIconMenu));
