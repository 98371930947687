import React, { useState } from "react";
import { connect } from "react-redux";

import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";

import { makeStyles } from "@material-ui/core/styles";
import BellIcon from "@material-ui/icons/Notifications";

import NotificationIconMenu from "./NotificationIconMenu";

function NotificationIcon(props) {
  const classes = useStyles();

  const { notifications } = props.user;

  const [anchorEl, setAnchorEl] = useState(null);

  // Open/close notifications menu
  function openMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <div className={classes.iconWrapper}>
      <IconButton
        className={classes.icon}
        aria-label="ielts success notification"
        onClick={openMenu}
      >
        <Badge badgeContent={notifications.length} color="secondary">
          <BellIcon />
        </Badge>
      </IconButton>
      {/* Notifications menu */}
      <NotificationIconMenu
        anchorEl={anchorEl}
        notifications={notifications}
        handleClose={() => setAnchorEl(null)}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: "flex",
    marginRight: "1rem",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  icon: {
    color: theme.customColors.blue,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(NotificationIcon);
