import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { useInterval } from "../../helpers/customHook";
import { analyseAlertLoading } from "../../actions/analysisActions";

// Loading spinner for writing/speaking analysis
function SpinnerAnalyse(props) {
  const classes = useStyles();
  const { loading } = props.analysis;
  const { isMockTest } = props.practice;

  const [time, setTime] = useState(0);
  const [text, setText] = useState("Uploading your answer...");

  // Reset timer
  useEffect(() => {
    if (!loading) {
      setTime(0);
      setText("Uploading your answer...");
    }
  }, [loading]);

  // Change text based on time
  useEffect(() => {
    if (time > 1 && time < 6) {
      setText("Analysis in progress...");
    } else if (time >= 6 && time < 12) {
      setText("More accurate than ever...");
    } else if (time >= 12 && time < 30) {
      setText("Still working on it...");
    } else if (time >= 30) {
      props.analyseAlertLoading(false);
    }
  }, [time]);

  // Timer for changing text
  useInterval(
    () => {
      setTime(time + 1);
    },
    !loading || isMockTest ? null : 1000
  );

  return loading ? ( // Prevent showing on review mode
    <div className={classes.overlay}>
      <div className={classes.box}>
        <img src="https://pineapplestudio.com.au/ielts_success/images/layout/analyse_anim.gif" />
        <Typography variant="h5">{text}</Typography>
      </div>
    </div>
  ) : null;
}

const useStyles = makeStyles((theme) => ({
  overlay: {
    top: 0,
    left: 0,
    zIndex: 9999,
    width: "100%",
    height: "100%",
    display: "flex",
    position: "fixed",
    alignItems: "center",
    background: "#00000080",
    justifyContent: "center",
  },
  box: {
    width: "20em",
    height: "20em",
    display: "flex",
    background: "white",
    borderRadius: "1em",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const mapStateToProps = (state) => ({
  analysis: state.analysis,
  practice: state.practice,
});

export default connect(mapStateToProps, { analyseAlertLoading })(
  SpinnerAnalyse
);
