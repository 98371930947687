import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";

import { navigateTo } from "../../actions/navigationActions";
import { ROUTE_DASHBOARD } from "../../helpers/types";
import getPlatformLayout from "../../helpers/getPlatformLayout";

// Platform logo at top left corner
function LogoIcon(props) {
  const classes = useStyles();

  return (
    <Avatar
      className={classes.logo}
      src={getPlatformLayout({ type: "logo" })}
      alt="ielts success - online practice free"
      onClick={() => props.navigateTo(ROUTE_DASHBOARD)}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "49px",
    height: "49px",
    margin: "7px",
    cursor: "pointer",
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
}));

export default connect(null, { navigateTo })(LogoIcon);
