import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import ListItem from "@material-ui/core/ListItem";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { markHasRead, markAllHasRead } from "../../actions/userActions";
import { navigateTo } from "../../actions/navigationActions";

import getNotification from "../../helpers/getNotification";

// Drop down notification menu
function NotificationIconMenu({ anchorEl, notifications, ...props }) {
  const classes = useStyles();

  // Render notifications
  function createNotifications(notifications) {
    return notifications.slice(0, 5).map((notification, id) => {
      // Get notification data
      const { title, icon, color, redirectLink } = getNotification(
        notification.type
      );
      if (!title) return;

      return (
        <ListItem
          className={clsx(classes.notif, {
            [classes.notifFirst]: id === 0,
          })}
          onClick={() => goTo(redirectLink, notification.id)}
        >
          <ListItemIcon style={{ color }}>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      );
    });
  }

  // Navigate to link
  function goTo(link, notificationId) {
    props.markHasRead({ notificationId });
    props.navigateTo(link);
    handleClose();
  }

  // Tell parent to close menu
  function handleClose() {
    props.handleClose();
  }

  // Remove all notifications
  function markAllHasRead() {
    props.markAllHasRead();
    props.handleClose();
  }

  return (
    <Menu
      elevation={1}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{ paper: classes.menu }}
    >
      <ClickAwayListener onClickAway={() => handleClose}>
        <div>
          <Typography variant="h6" className={classes.title}>
            Notifications
          </Typography>
          {notifications.length > 0 ? (
            <>
              <MenuList>
                {/* Render notifications */}
                {createNotifications(notifications)}
              </MenuList>
              <Typography
                className={classes.allRead}
                onClick={() => markAllHasRead()}
              >
                Mark all as read
              </Typography>
            </>
          ) : (
            <div className={classes.logoWrapper} onClick={() => handleClose()}>
              {/* Logo if no notifications */}
              <CardMedia
                className={classes.logo}
                image="https://pineapplestudio.com.au/ielts_success/images/layout/notif_empty.png"
                alt="ielts success notification"
              />
              <Typography className={classes.category}>All good!</Typography>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </Menu>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const useStyles = makeStyles((theme) => ({
  menu: {
    minWidth: "20rem",
    maxWidth: "25rem",
    minHeight: "20rem",
    borderRadius: "2px",
    border: "solid 1px lightgray",
    background: theme.palette.background.paper,
  },
  notif: {
    margin: "0.5rem",
    minHeight: "4rem",
    cursor: "pointer",
    borderRadius: "2px",
    width: "calc(100% - 1rem)",
    background: theme.palette.background.paper,
    border: `solid 1px ${theme.palette.grey[300]}`,
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
  notifFirst: {
    marginTop: 0,
  },
  title: {
    marginLeft: "1rem",
    fontWeight: "bold",
  },
  allRead: {
    float: "right",
    cursor: "pointer",
    fontSize: "0.9rem",
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
    color: theme.customColors.red,
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
  logo: {
    width: "5rem",
    height: "5rem",
  },
  logoWrapper: {
    height: "15rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default connect(mapStateToProps, {
  markHasRead,
  markAllHasRead,
  navigateTo,
})(NotificationIconMenu);
