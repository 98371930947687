import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ROUTE_PRICING } from "../../helpers/types";
import { useInterval } from "../../helpers/customHook";
import { navigateTo } from "../../actions/navigationActions";
import { activatePromotionUid } from "../../actions/pricingActions";

// Empty component to get promo ID from email campaigns
function PromoReceiver(props) {
  const [timeLeft, setTimeLeft] = useState(2);

  const urlParams = new URLSearchParams(window.location.search);
  const uid = urlParams.get("uid");
  const promoId = urlParams.get("promoId");
  const redirect = urlParams.get("redirect");
  // http://localhost:3000/promo?promoId=offer_5e4a6048e7179a2f011cee6a&uid=6101fe057a99780d2a8a3404&redirect=mock-test

  // Countdown to slow down and wait for user if available
  useInterval(
    () => {
      setTimeLeft(timeLeft - 1);
    },
    timeLeft <= 0 ? null : 1000
  );

  // Save promo in user and go to Dashboard
  useEffect(() => {
    if (timeLeft <= 0) {
      if (uid && promoId) {
        props.activatePromotionUid({
          uid,
          promoId,
          token: "token", // Simple trick to prevent unauthorized use
        });
      }

      props.navigateTo(redirect ? redirect : ROUTE_PRICING);
    }
  }, [timeLeft]);

  return null;
}

export default connect(null, { navigateTo, activatePromotionUid })(
  PromoReceiver
);
