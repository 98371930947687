import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import {
  ROUTE_HOME,
  ROUTE_BLOG,
  ROUTE_HELP,
  ROUTE_LOGIN,
  ROUTE_TERMS,
  ROUTE_SIGNUP,
  ROUTE_PRIVACY,
  ROUTE_PRICING,
  ROUTE_PRACTICE,
  ROUTE_MOCK_TEST,
  ROUTE_MOBILE_APP,
  ROUTE_TESTIMONIALS,
  ROUTE_MOBILE_APP_IOS,
  ROUTE_FREE_READ_ALOUD,
  ROUTE_FREE_SHORT_ANSWER,
  ROUTE_MOBILE_APP_HUAWEI,
  ROUTE_MOBILE_APP_ANDROID,
  ROUTE_MOCK_TEST_SCORECARD,
  ROUTE_FREE_WRITE_DICTATION,
  ROUTE_FREE_REPEAT_SENTENCE,
} from "../../helpers/types";

import { getBlogContent } from "../../actions/blogActions";

//Display dynamic Metadata for a page
function MetadataManager(props) {
  const { pathname } = props.location;
  const [metadata, setMetadata] = useState(null);

  // Fetch metadata if blog or routes
  useEffect(() => {
    if (pathname.includes("/blog/")) {
      getBlogContent();
    } else if (pathname.includes(ROUTE_MOCK_TEST_SCORECARD)) {
      getScorecardContent();
    } else {
      displayMetadata();
    }
  }, [pathname]);

  // Get blog metadata
  async function getBlogContent() {
    // Get slug from pathname
    const slug = pathname.replace("/blog/", "");
    const data = await props.getBlogContent({ slug });
    if (data && data.metadata) {
      setMetadata({
        url: data.url,
        image: data.image,
        title: data.metadata.title,
        description: data.metadata.description,
      });
    }
  }

  // Get scorecard metadata
  function getScorecardContent() {
    // Get slug from pathname
    const slug = pathname.replace("/mock-test/scorecard/", "");
    setMetadata({
      title: "Get Your Mock Test Scorecard With IELTS Success",
      description:
        "Take a FREE scored mock test with IELTS Success when you register an account. Crack the IELTS Exam on your first attempt!",
      image: `https://ielts-success-2021.s3.amazonaws.com/scorecards/${
        slug || ""
      }.png`,
      url: `https://www.ieltssuccess.com.au${ROUTE_MOCK_TEST_SCORECARD}/${
        slug || ""
      }`,
    });
  }

  // Get route metadata
  function displayMetadata() {
    const data = getMetadata();
    setMetadata(data);
  }

  //Return route metadata based on pathname
  function getMetadata() {
    switch (pathname) {
      case ROUTE_HOME:
        return {
          title:
            "The Best IELTS Study Tool For A Real IELTS Preparation ❤️ | IELTS Success",
          description:
            "IELTS Success is a fantastic tool that boosts your score to 90! Start using our IELTS software right now! No account needed - Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_HOME}`,
        };
      case ROUTE_SIGNUP:
        return {
          title: "Create An Account | IELTS Success",
          description:
            "Get direct access to 100+ Free IELTS practice questions, IELTS study tips and more. Sign up here.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_SIGNUP}`,
        };
      case ROUTE_LOGIN:
        return {
          title: "Log In To Your Account | IELTS Success",
          description:
            "IELTS Login. Get The Latest IELTS Exam questions! Improve Your Score Now With 1000+ Repeated Questions. Login here.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_LOGIN}`,
        };
      case ROUTE_BLOG:
        return {
          title: "Blog | IELTS Success",
          description: "Find and read over 40 articles to improve your score.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_BLOG}`,
        };
      case ROUTE_TESTIMONIALS:
        return {
          title: "Review What 550+ Users Have To Say 😍",
          description:
            "User review. What do our users love about us? You can read over 550 reviews to discover what is good about IELTS Success.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_TESTIMONIALS}`,
        };
      case ROUTE_HELP:
        return {
          title:
            "Need Help With IELTS Exam? 🤔 30+ Questions Answered | IELTS Success",
          description:
            "Don't invest a cent in IELTS before at least knowing this! We've answered all the most commonly asked questions to get you on the right path.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_HELP}`,
        };
      case ROUTE_PRIVACY:
        return {
          title: "Privacy Policy | IELTS Success",
          description:
            "IELTS Success is a secured and safe Pearson Test of English academic platform. Check our privacy policy for more information on how your data is handled.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_PRIVACY}`,
        };
      case ROUTE_TERMS:
        return {
          title: "Terms and Conditions | IELTS Success",
          description:
            "IELTS Success is a secured and safe IELTS platform. Check our privacy policy for more information regarding the treatment of your data.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_TERMS}`,
        };
      case ROUTE_PRACTICE:
        return {
          title: "Free Practice | IELTS Success",
          description:
            "A direct access to your free practice. IELTS Success contains more than 5000 exam questions and 10 scored mock tests.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_PRACTICE}`,
        };

      case ROUTE_MOCK_TEST:
        return {
          title: "Scored Mock Tests | IELTS Success",
          description:
            "Get instant results and feedback with one of our 23 scored mock tests. Low price, starts from only AUD $15. Try one now.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_PRACTICE}`,
        };

      case ROUTE_PRICING:
        return {
          title:
            "⭐ Buy IELTS Mock Test and IELTS Preparation Kit to Boost Your Practice | IELTS Success",
          description:
            "Choose from our Ultimate, Silver or Bronze packages to familiarise you with the format of IELTS Academic. All our plans include IELTS AI Scoring, Prediction Questions, Speech and Essay Analysis and other IELTS tools.",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_PRICING}`,
        };

      case ROUTE_MOBILE_APP:
        return {
          title: "Download The Best IELTS Preparation App | IELTS Success",
          description:
            "Get the IELTS Success mobile app to practice prediction questions with Ai scoring. This is a fantastic tool that boosts your score to 90! Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_MOBILE_APP}`,
        };

      case ROUTE_MOBILE_APP_IOS:
        return {
          title:
            "Download The Best IELTS Preparation App on iOS | IELTS Success",
          description:
            "Get the IELTS Success iOS mobile app to practice prediction questions with Ai scoring. This is a fantastic tool that boosts your score to 90! Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_MOBILE_APP_IOS}`,
        };

      case ROUTE_MOBILE_APP_ANDROID:
        return {
          title:
            "Download The Best IELTS Preparation App on Android | IELTS Success",
          description:
            "Get the IELTS Success Android mobile app to practice prediction questions with Ai scoring. This is a fantastic tool that boosts your score to 90! Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_MOBILE_APP_ANDROID}`,
        };

      case ROUTE_MOBILE_APP_HUAWEI:
        return {
          title:
            "Download The Best IELTS Preparation App on Huawei | IELTS Success",
          description:
            "Get the IELTS Success mobile app on the App Gallery to practice prediction questions with Ai scoring. This is a fantastic tool that boosts your score to 90! Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: `https://www.ieltssuccess.com.au${ROUTE_MOBILE_APP_HUAWEI}`,
        };

      default:
        return {
          title:
            "The Best IELTS Study Tool For A Real IELTS Preparation ❤️ | IELTS Success",
          description:
            "IELTS Success is a fantastic tool that boosts your score to 90! Start using our IELTS software right now! No account needed - Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_share_banner.webp",
          url: "https://www.ieltssuccess.com.au",
        };
    }
  }

  if (!metadata) return null;
  const { title, description, image, url } = metadata;

  // Main return function
  return (
    <Helmet>
      {/* General */}
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Facebook */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="IELTS Success" />
      <meta property="og:description" content={description} />

      {/* Twitter */}
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default connect(null, { getBlogContent })(withRouter(MetadataManager));
