import { START_LOADING, FINISH_LOADING } from "../helpers/types";

const initialState = false;

export default function (state = initialState, action) {
  switch (action.type) {
    case START_LOADING:
      return true;

    case FINISH_LOADING:
      return false;

    default:
      return state;
  }
}
