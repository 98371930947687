// Return title and logo of the platform
const getPlatformLayout = ({ type }) => {
  // Default
  var title = "IELTS Success";
  var logo =
    "https://pineapplestudio.com.au/ielts_success/images/layout/ielts_success_logo_v2.png";

  return type === "title" ? title : logo;
};

export default getPlatformLayout;
