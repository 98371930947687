import {
  GET_ANALYSIS,
  ANALYSIS_LOADING,
  SET_ANALYSIS_SCORE,
  CLEAR_ANALYSIS_SCORE,
} from "../helpers/types";

const initialState = {
  analysis: null,
  score: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ANALYSIS:
      return {
        ...state,
        analysis: action.payload,
      };
    case ANALYSIS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_ANALYSIS_SCORE:
      return {
        ...state,
        score: action.payload,
        loading: false,
      };

    case CLEAR_ANALYSIS_SCORE:
      return {
        ...state,
        analysis: null,
        loading: false,
        score: null,
      };

    default:
      return state;
  }
}
