import {
  SILVER,
  ULTIMATE,
  IS_NIGHT_MODE,
  SILVER_DISCOUNT,
  STEPS_AUTO_NIGHT,
  ULTIMATE_DISCOUNT,
  FREE_WRITING_CREDITS,
} from "./types";
import isEmpty from "./isEmpty";

const moment = require("moment");

// Check if user is authenticated
const isAuthenticated = (user) => {
  return !isEmpty(user);
};

// Check if user is subscribed
const isPaidStudent = (user) => {
  return user.paidExpiryTime > Date.now();
};

// Check if user has mobile device linked
const isMobileUser = (user) => {
  return !isEmpty(user.redeemedMobileApp);
};

// Check if user is registered in last 30 minutes
const isNewUser = (user) => {
  const today = new Date();
  const mins30 = today.setMinutes(today.getMinutes() - 30);
  return isAuthenticated(user) && moment(user.dateCreated).isAfter(mins30);
};

// Check if user is referred
const isReferredUser = (user) => {
  return isAuthenticated(user) && !isEmpty(user.referralId);
};

// Check if user is in practice mode
const isInPracticeMode = (practice) => {
  return !isEmpty(practice.uids);
};

// Check if student from previous version and still is paid
const isUltimateStudent = (user) => {
  return (
    !isEmpty(user) &&
    user.platformAccess === "student" &&
    (user.purchaseHistory.includes(ULTIMATE) ||
      user.purchaseHistory.includes(ULTIMATE_DISCOUNT)) &&
    user.paidExpiryTime > Date.now()
  );
};

// Check if student from previous version and still is paid
const isPremiumOrUltimateStudent = (user) => {
  return (
    !isEmpty(user) &&
    user.platformAccess === "student" &&
    (user.purchaseHistory.includes(SILVER) ||
      user.purchaseHistory.includes(SILVER_DISCOUNT) ||
      user.purchaseHistory.includes(ULTIMATE) ||
      user.purchaseHistory.includes(ULTIMATE_DISCOUNT)) &&
    user.paidExpiryTime > Date.now()
  );
};

// Return night mode from user or local storage if not login
const isNightMode = (user) => {
  if (!isEmpty(user)) {
    if (doesUserHave(user, STEPS_AUTO_NIGHT)) {
      // Automatic night mode between 8pm and 8am
      const isNightTime =
        moment().format("HH") < (user.nightTimeEnd || 8) ||
        moment().format("HH") >= (user.nightTimeStart || 20);
      return isNightTime;
    } else {
      return user.isNightMode;
    }
  } else {
    return Boolean(localStorage[IS_NIGHT_MODE] === "true");
  }
};

// Check if user has property in stepsDone or local storage if not login
const doesUserHave = (user, step) => {
  if (!isEmpty(user)) {
    return user.stepsDone.includes(step);
  } else {
    return Boolean(localStorage[step] === "true");
  }
};

// Check if user can submit writing
const canSubmitWriting = (user, isMockTest) => {
  return (
    (isAuthenticated(user) && (user.writingCredits > 0 || isMockTest)) ||
    (!isAuthenticated(user) &&
      parseInt(localStorage[FREE_WRITING_CREDITS] || 5) > 0)
  );
};

// Check if user can open the question
const hasAccess = (uid, user) => {
  // User should be logged in and paid user / otherwise free question only
  return (
    allowedQuestions().includes(uid) ||
    (isAuthenticated(user) && user.paidExpiryTime > Date.now())
  );
};

function allowedQuestions() {
  return [
    "RT1_2",
    "RT2_3",
    "RT3_1",

    "LT1_3_1",
    "LT2_1_2",
    "LT3_1_3",
    "LT4_1_4",

    "ST1_1",
    "ST2_1",
    "ST3_1635490935",

    "WT1A_20",
    "WT2_1",
    "WT1G_2_12",
  ];
}

export {
  isNewUser,
  hasAccess,
  isNightMode,
  doesUserHave,
  isMobileUser,
  isPaidStudent,
  isReferredUser,
  isAuthenticated,
  isInPracticeMode,
  canSubmitWriting,
  allowedQuestions,
  isUltimateStudent,
  isPremiumOrUltimateStudent,
};
