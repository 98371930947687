import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Onboarding large image description
function OnBoardingLargeDescription({ config, currentIndex }) {
  const classes = useStyles();
  const { descriptions, images, steps } = config;

  return (
    <div className={classes.container}>
      <img
        width="672"
        height="420"
        alt={`ielts onboarding ${images[currentIndex]}`}
        className={classes.image}
        src={`https://pineapplestudio.com.au/ielts_success/images/layout/${images[currentIndex]}.webp`}
      />
      <div className={classes.bottom}>
        <Typography variant="h5">{steps[currentIndex]}</Typography>
        <Typography variant="body1" style={{ marginTop: "1rem" }}>
          {descriptions[currentIndex]}
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexBasis: "75%",
    background: theme.customColors.blueDark,
  },
  bottom: {
    color: "white",
    padding: theme.spacing(2),
  },
  image: {
    width: "100%",
    minHeight: "300px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minHeight: "auto",
      objectFit: "contain",
    },
  },
}));

export default OnBoardingLargeDescription;
