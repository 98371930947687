import { FREE, ULTIMATE, HAS_CPC_OFFER, HAS_CPC_OFFER_ALL } from "./types";
import { isReferredUser } from "./userCheck";

// Return promo details for cpc retarget sales
const getCpcPromo = (cpc = null, cpc_all = null, user = {}) => {
  if (cpc || localStorage[HAS_CPC_OFFER]) {
    return {
      packs: [FREE, ULTIMATE],
      startPromo: parseInt(localStorage[HAS_CPC_OFFER]) || 0,
      target: [ULTIMATE],
      discount: 30,
    };
  } else if (cpc_all || localStorage[HAS_CPC_OFFER_ALL]) {
    return {
      packs: [FREE, ULTIMATE],
      startPromo: parseInt(localStorage[HAS_CPC_OFFER_ALL]) || 0,
      target: [ULTIMATE],
      discount: 50,
    };
  } else if (isReferredUser(user)) {
    return {
      packs: [FREE, ULTIMATE],
      startPromo: parseInt(localStorage[HAS_CPC_OFFER]) || 0,
      target: [ULTIMATE],
      discount: 30,
    };
  } else {
    return {
      packs: [FREE, ULTIMATE],
      startPromo: 0,
      target: [],
      discount: 0,
    };
  }
};

export default getCpcPromo;
