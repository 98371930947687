import { GET_SNACKBAR, CLEAR_SNACKBAR } from "../helpers/types";

// @desc  Trigger bottom snackbar notification
export const displaySnackbar = (notification) => (dispatch) => {
  dispatch({ type: GET_SNACKBAR, payload: notification });
};

// @desc  Clear bottom snackbar notification
export const clearSnackbar = () => (dispatch) => {
  dispatch({ type: CLEAR_SNACKBAR });
};
