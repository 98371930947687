import React from "react";

import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { makeStyles, withStyles } from "@material-ui/core/styles";

// Html tooltip
function TooltipCustom({
  title = "Note from IELTS Success",
  subTitle = "",
  subTitle2 = "",
  subTitle3 = "",
  component = null,
}) {
  const classes = useStyles();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 250,
      borderRadius: "2px",
      border: "1px solid lightgray",
      color: theme.customColors.blackWhite,
      fontSize: theme.typography.pxToRem(12),
      background: theme.palette.background.paper,
    },
  }))(Tooltip);

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="inherit">{title}</Typography>
          {subTitle}
          <br />
          {subTitle2}

          {subTitle3 && (
            <>
              <br />
              {subTitle3}
            </>
          )}
        </React.Fragment>
      }
    >
      {component ? component : <InfoIcon className={classes.info} />}
    </HtmlTooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  info: {
    cursor: "pointer",
    fontSize: "1.2rem",
    color: theme.customColors.blueDark,
  },
}));

export default TooltipCustom;
