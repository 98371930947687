import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import isEmpty from "../../helpers/isEmpty";
import { ROUTE_PRACTICE } from "../../helpers/types";
import { verifyMultiAccount } from "../../actions/userActions";
import { clearNavigate } from "../../actions/navigationActions";

// Allow navigation to any route from anywhere (check for multi sessions)
function NavigationManager(props) {
  const { navigation, user } = props;

  // Push route to history
  useEffect(() => {
    if (navigation) {
      props.history.push(navigation);
      props.clearNavigate();
    }
  }, [navigation]);

  // Check for multi account in Practice route (limit calls)
  useEffect(() => {
    if (!isEmpty(user) && window.location.pathname === ROUTE_PRACTICE) {
      props.verifyMultiAccount(user);
    }
  }, [window.location.pathname]);

  return null;
}

const mapStateToProps = (state) => ({
  user: state.user,
  navigation: state.navigation,
});

export default connect(mapStateToProps, { clearNavigate, verifyMultiAccount })(
  withRouter(NavigationManager)
);
