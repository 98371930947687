import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { ROUTE_LOGIN } from "../../helpers/types";
import { isAuthenticated } from "../../helpers/userCheck";

// Protected route for authenticated user only
function PrivateRoute({ component: Component, user, ...props }) {
  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated(user) ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTE_LOGIN} />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PrivateRoute);
