import React from "react";

// Cutout box in onbording
function CutoutBox({ className, cutout }) {
  const { top, bottom, left, right, width, height, icon } = cutout;

  return (
    <div
      className={className}
      style={{
        width: width,
        height: height,
        top: top || "",
        left: left || "",
        right: right || "",
        bottom: bottom || "",
      }}
    >
      {icon}
    </div>
  );
}

export default CutoutBox;
