import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
  ROUTE_DASHBOARD,
  ROUTE_SET_PASSWORD,
  STEPS_SHOULD_SET_PASSWORD,
} from "../../helpers/types";
import { isAuthenticated } from "../../helpers/userCheck";

// Redirect authenticated user to dashboard
function PublicOnlyRoute({ component: Component, user, ...props }) {
  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated(user) ? (
          user?.stepsDone?.includes(STEPS_SHOULD_SET_PASSWORD) ? (
            // Force set password alert
            <Redirect to={ROUTE_SET_PASSWORD} />
          ) : (
            <Redirect to={ROUTE_DASHBOARD} />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PublicOnlyRoute);
