import axios from "axios";

import {
  SNACK_EMAIL_VERIFY_SENT,
  STEPS_DID_SEND_VERIFY_EMAIL,
} from "../helpers/types";
import { handleErrors } from "./errorActions";
import { updateUserArray } from "./userActions";
import { displaySnackbar } from "./notificationActions";

// @desc  Save user to SendInBlue
export const saveToMailList = () => (dispatch) => {
  axios
    .post("/api/maillist/saveToMailList")
    .then((res) => {
      dispatch(displaySnackbar(SNACK_EMAIL_VERIFY_SENT));
      dispatch(updateUserArray({ stepsDone: STEPS_DID_SEND_VERIFY_EMAIL }));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Trigger abandonned cart in SendInBlue
export const didAddToCart = (userData) => (dispatch) => {
  axios
    .post("/api/maillist/didAddToCart", userData)
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Remove verify token in SendInBlue
export const saveToVerifiedMailList = (userData) => (dispatch) => {
  axios
    .post("/api/maillist/saveToVerifiedMailList", userData)
    .catch((error) => dispatch(handleErrors(error)));
};
