import { combineReducers } from "redux";
import notificationReducer from "./notificationReducer";
import testimonialsReducer from "./testimonialsReducer";
import statisticsReducer from "./statisticsReducer";
import navigationReducer from "./navigationReducer";
import promotionReducer from "./promotionReducer";
import mockTestReducer from "./mockTestReducer";
import analysisReducer from "./analysisReducer";
import practiceReducer from "./practiceReducer";
import recorderReducer from "./recorderReducer";
import questionReducer from "./questionReducer";
import loadingReducer from "./loadingReducer";
import packageReducer from "./packageReducer";
import errorReducer from "./errorReducer";
import blogReducer from "./blogReducer";
import userReducer from "./userReducer";

export default combineReducers({
  notification: notificationReducer,
  testimonials: testimonialsReducer,
  statistics: statisticsReducer,
  navigation: navigationReducer,
  promotions: promotionReducer,
  mocktests: mockTestReducer,
  questions: questionReducer,
  recorder: recorderReducer,
  analysis: analysisReducer,
  practice: practiceReducer,
  packages: packageReducer,
  loading: loadingReducer,
  errors: errorReducer,
  blogs: blogReducer,
  user: userReducer,
});
