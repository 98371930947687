import { GET_SNACKBAR, CLEAR_SNACKBAR } from "../helpers/types";

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SNACKBAR:
      return action.payload;

    case CLEAR_SNACKBAR:
      return null;

    default:
      return state;
  }
}
