import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";

import withWidth from "@material-ui/core/withWidth";

import {
  ROUTE_EXERCISE,
  ROUTE_PRACTICE,
  ROUTE_DESKTOP_ONLY,
} from "../../helpers/types";

// List of URL to trigger Desktop only alert
const triggerUrl = [ROUTE_EXERCISE, ROUTE_PRACTICE];

// Redirect mobile users to desktop only alert
function NoMobileRoute({ component: Component, ...props }) {
  const isSmall = props.width === "xs";

  return (
    <Route
      {...props}
      render={(props) =>
        triggerUrl.includes(window.location.pathname) && isSmall ? (
          <Redirect to={ROUTE_DESKTOP_ONLY} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default withRouter(withWidth()(NoMobileRoute));
