import axios from "axios";

import { GET_BLOGS } from "../helpers/types";

import { handleErrors } from "./errorActions";
import { startLoadingSpinner, stopLoadingSpinner } from "./loadingActions";

// @desc  Fetch all approved blogs
// @next  Load blogs to redux
export const fetchAllBlogs = () => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .get("/api/blog/fetchAllBlogs")
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch({ type: GET_BLOGS, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Get one particular blog
// @next  Return blog data
export const getBlogContent = (slug) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/blog/getBlogContent", slug)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// @desc  Vote blog as helpful/unhelpful
export const giveBlogFeedback = (slug) => (dispatch) => {
  axios
    .post("/api/blog/giveBlogFeedback", slug)
    .catch((error) => dispatch(handleErrors(error)));
};
