import React from "react";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Onboarding large image steps
function OnBoardingLargeSteps({ currentIndex, config, ...props }) {
  const classes = useStyles();
  const { title, steps } = config;
  const lastIndex = currentIndex === steps.length - 1;

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.header} variant="h5">
          {title}
        </Typography>
        <div style={{ marginTop: "2rem" }}>
          {steps.map((step, index) => {
            return (
              <Typography
                key={step}
                variant="body2"
                onClick={() => props.selectIndex(index)}
                className={clsx(classes.stepsDefault, {
                  [classes.currentStep]: currentIndex === index,
                })}
              >
                {step}
              </Typography>
            );
          })}
        </div>
      </div>
      <Typography className={classes.btn} onClick={() => props.increaseIndex()}>
        {lastIndex ? "Start" : "Next"}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexBasis: "25%",
    flexDirection: "column",
    backgroundColor: "#4a4a4a",
    padding: theme.spacing(4, 4),
    justifyContent: "space-between",
  },
  header: {
    color: "white",
  },
  stepsDefault: {
    color: "lightgray",
    marginTop: "1rem",
    cursor: "pointer",
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
  currentStep: {
    color: "white",
    fontWeight: 600,
    cursor: "default",
    paddingLeft: "0.5rem",
    borderLeft: "1.5px lightgray solid",
  },
  btn: {
    width: "7em",
    color: "white",
    display: "flex",
    height: "2.5em",
    cursor: "pointer",
    borderRadius: "3px",
    alignItems: "center",
    justifyContent: "center",
    background: theme.customColors.greenDarker,
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
    },
  },
}));

export default OnBoardingLargeSteps;
