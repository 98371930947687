import React from "react";

import {
  ROUTE_USER,
  ROUTE_REWARD,
  ROUTE_PRACTICE,
  ROUTE_MOCK_TEST,
  NOTIF_NEW_REFERRAL,
  NOTIF_DID_SUBSCRIBED,
  NOTIF_BOUGHT_ADD_ON,
  NOTIF_ORIGINAL_PLATFORM,
  NOTIF_SHOULD_FINISH_PROFILE,
} from "./types";

import FaceIcon from "@material-ui/icons/Face";
import DashboardIcon from "@material-ui/icons/Dashboard";

// Return notification object based on id
const getNotification = (notificationId) => {
  if (notificationId === NOTIF_DID_SUBSCRIBED) {
    return {
      type: "Success",
      title: "Purchase successful! You can start practicing.",
      redirectLink: ROUTE_PRACTICE,
      icon: <DashboardIcon />,
      color: "#65ab00",
    };
  } else if (notificationId === NOTIF_BOUGHT_ADD_ON) {
    return {
      type: "Success",
      title: "Purchase successful. Thank you!",
      redirectLink: ROUTE_MOCK_TEST,
      icon: <DashboardIcon />,
      color: "#65ab00",
    };
  } else if (notificationId === NOTIF_SHOULD_FINISH_PROFILE) {
    return {
      type: "Success",
      title: "Welcome! Complete your profile for a better experience.",
      redirectLink: ROUTE_USER,
      icon: <DashboardIcon />,
      color: "#65ab00",
    };
  } else if (notificationId === NOTIF_NEW_REFERRAL) {
    return {
      type: "Success",
      title: "Well done! You got a new referral!",
      redirectLink: ROUTE_REWARD,
      icon: <FaceIcon />,
      color: "#65ab00",
    };
  } else if (notificationId === NOTIF_ORIGINAL_PLATFORM) {
    return {
      type: "Success",
      title:
        "You have been upgraded! You can switch to the previous version at anytime.",
      redirectLink: ROUTE_USER,
      icon: <FaceIcon />,
      color: "#65ab00",
    };
  } else {
    return {
      type: "",
      title: "",
      redirectLink: null,
      icon: null,
      color: "",
    };
  }
};

export default getNotification;
