import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";
import SchoolIcon from "@material-ui/icons/School";
import withWidth from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";

import isEmpty from "../../helpers/isEmpty";
import { ROUTE_PRICING } from "../../helpers/types";
import { useInterval } from "../../helpers/customHook";

import { navigateTo } from "../../actions/navigationActions";

import "../../css/shaking.css";
const moment = require("moment");

// Premium button in navbar
function PremiumIcon({ currentPromo, ...props }) {
  const classes = useStyles();
  const isSmall = props.width === "xs";

  var endTime = 0;
  var discount = 0;
  var startTime = 0;
  var promoId = "";

  const [timeLeft, setTimeLeft] = useState(0);

  // Check if active promo
  if (currentPromo) {
    endTime = currentPromo.endTime;
    promoId = currentPromo.promoId;
    discount = currentPromo.discount;
    startTime = currentPromo.startTime;
  }

  // Countdown timer
  useInterval(
    () => {
      setTimeLeft(timeLeft - 1000);
    },
    !timeLeft || startTime > Date.now() || Date.now() > endTime ? null : 1000
  );

  // Set end time when get current promo
  useEffect(() => {
    if (currentPromo) {
      setTimeLeft(currentPromo.endTime - Date.now());
    }
  }, [currentPromo]);

  if (isEmpty(currentPromo) || timeLeft <= 0) {
    // Premium button
    return (
      <div
        className={classes.wrapper}
        onClick={() => props.navigateTo(ROUTE_PRICING)}
      >
        <SchoolIcon className={classes.icon} />
        {isSmall ? "Premium" : "Go Premium"}
      </div>
    );
  } else {
    // Promo button
    return (
      <Tooltip title={`${discount}% offer is available now!`}>
        <div
          className={classes.promoWrapper}
          onClick={() => props.navigateTo(ROUTE_PRICING)}
        >
          <img
            alt="IELTS Success promo"
            className={clsx("shaking__item", { [classes.promoIcon]: true })}
            src="https://pineapplestudio.com.au/ielts_success/images/layout/gift_icon.png"
          />
          {moment.duration(timeLeft, "milliseconds").asDays() > 1
            ? `${Math.round(
                moment.duration(timeLeft, "milliseconds").asDays()
              )} day${
                moment.duration(timeLeft, "milliseconds").asDays() > 2
                  ? "s"
                  : ""
              } left`
            : moment.utc(timeLeft).format("HH:mm:ss", { trim: false })}
        </div>
      </Tooltip>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    fontSize: "1.2em",
    fontWeight: "bold",
    borderRadius: "2em",
    alignItems: "center",
    background: "#ffda22",
    padding: "0.4em 0.8em",
    justifyContent: "center",
    boxShadow: theme.shadows[2],
    transition: "all .2s ease-in-out",
    textShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
      transform: "scale(1.03)",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  icon: {
    textShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    paddingRight: "0.5em",
  },
  promoWrapper: {
    display: "flex",
    fontWeight: "bold",
    borderRadius: "5px",
    textAlign: "center",
    alignItems: "center",
    padding: "0.3em 0.5em",
    justifyContent: "center",
    background: theme.customColors.greenDarker,
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
      transform: "scale(1.03)",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  promoIcon: {
    height: "2em",
    margin: "0 0.5em",
  },
}));

export default connect(null, {
  navigateTo,
})(withWidth()(PremiumIcon));
