import React from "react";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";

import store from "../src/reducers/store";
import ThemeProvider from "./ThemeProvider";

import { JWT_TOKEN } from "./helpers/types";
import setAuthToken from "./helpers/setAuthToken";
import { retrieveUser } from "./actions/userActions";

// Check for existing token in cookies
if (localStorage[JWT_TOKEN]) {
  // Set auth token to header requests
  setAuthToken(localStorage[JWT_TOKEN]);

  // Decode token and check if shoulg logout
  const decoded = jwt_decode(localStorage[JWT_TOKEN]);
  const shouldLogout = decoded.exp < Date.now() / 1000;
  store.dispatch(retrieveUser({ id: decoded.id, shouldLogout }));
}

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider />
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
