import React from "react";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";

import Parent from "./Parent";
import getTheme from "./helpers/getTheme";
import { isNightMode } from "./helpers/userCheck";

// Provide Material-UI theme to Parent
function ThemeProvider(props) {
  const { user } = props;

  return (
    <MuiThemeProvider theme={getTheme(isNightMode(user))}>
      <Parent />
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ThemeProvider);
